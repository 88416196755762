import { QueryRequest } from "../../types/store/ReduxMiddleWareType";
import { InputDataAndRatingsDashboardsActionTypes } from "../../types/store/InputDataAndRatingsDashboardsType";

export const getInputDataProduction = (
  year: number
): QueryRequest<InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_PRODUCTION> => {
  return {
    type: InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_PRODUCTION,
    payload: {
      request: {
        url: `data/hackathon/dashboard1/production/${year}`,
        method: "GET"
      }
    }
  };
};

export const getInputDataGrossRevenue = (
  year: number
): QueryRequest<InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_GROSS_REVENUE> => {
  return {
    type: InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_GROSS_REVENUE,
    payload: {
      request: {
        url: `data/hackathon/dashboard1/grossrevenue/${year}`,
        method: "GET"
      }
    }
  };
};

export const getInputDataOpex = (
  year: number
): QueryRequest<InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_OPEX> => {
  return {
    type: InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_OPEX,
    payload: {
      request: {
        url: `data/hackathon/dashboard1/opex/${year}`,
        method: "GET"
      }
    }
  };
};

export const getInputDataNetRevenue = (
  year: number
): QueryRequest<InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_NET_REVENUE> => {
  return {
    type: InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_NET_REVENUE,
    payload: {
      request: {
        url: `data/hackathon/dashboard1/netrevenue/${year}`,
        method: "GET"
      }
    }
  };
};

export const getInputDataCapex = (
  year: number
): QueryRequest<InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_CAPEX> => {
  return {
    type: InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_CAPEX,
    payload: {
      request: {
        url: `data/hackathon/dashboard1/capex/${year}`,
        method: "GET"
      }
    }
  };
};

export const getInputDataFreeCashFlow = (
  year: number
): QueryRequest<InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_FREE_CASH_FLOW> => {
  return {
    type: InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_FREE_CASH_FLOW,
    payload: {
      request: {
        url: `data/hackathon/dashboard1/freecashflow/${year}`,
        method: "GET"
      }
    }
  };
};

export const getInputDataROACE = (
  year: number
): QueryRequest<InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_ROACE> => {
  return {
    type: InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_ROACE,
    payload: {
      request: {
        url: `data/hackathon/dashboard1/roace/${year}`,
        method: "GET"
      }
    }
  };
};

export const getRating = (
  year: number
): QueryRequest<InputDataAndRatingsDashboardsActionTypes.GET_RATING> => {
  return {
    type: InputDataAndRatingsDashboardsActionTypes.GET_RATING,
    payload: {
      request: {
        url: `data/hackathon/dashboard1/rating/${year}`,
        method: "GET"
      }
    }
  };
};
