import React from "react";
import styled from "@emotion/styled";

import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  Chip as MuiChip,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import { spacing } from "@mui/system";
import ExportDropdown from "../../../components/ExportDropdown";
import { D12DataType, EUpdateStatus } from "../../../types/store/DashboardsType";
import Loader from "../../../components/Loader";

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props: any) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props: any) => props.theme.palette.common.white};
`;

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props: any) => props.theme.spacing(12)});
`;

const getRows = (data: D12DataType[]) => {
  return data.map((item, index) => ({
    id: index,
    name: item.name,
    start: item.startDate,
    end: item.endDate,
    countOfRows: item.countOfRows,
    state: (
      <Chip label={item.state} color={item.state === EUpdateStatus.UPDATED ? "success" : "error"} />
    )
  }));
};

interface IProps {
  data: D12DataType[];
  loading: boolean;
}

const TableD12: React.FC<IProps> = ({ data, loading }) => {
  return (
    <Card mb={6}>
      <CardHeader action={<ExportDropdown />} />

      <CardContent>
        {loading ? (
          <Loader />
        ) : (
          <Paper>
            <TableWrapper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Repository</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Number Of Entries</TableCell>
                    <TableCell>Repository Update Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getRows(data).map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component='th' scope='row'>
                        {row.name}
                      </TableCell>
                      <TableCell>{row.start}</TableCell>
                      <TableCell>{row.end}</TableCell>
                      <TableCell>{row.countOfRows}</TableCell>
                      <TableCell>{row.state}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableWrapper>
          </Paper>
        )}
      </CardContent>
    </Card>
  );
};

export default TableD12;
