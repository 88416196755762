import { FailureResponse, QueryRequest, SuccessResponse } from "./ReduxMiddleWareType";

export enum InputDataAndRatingsDashboardsActionTypes {
  GET_INPUT_DATA_PRODUCTION = "GET_INPUT_DATA_PRODUCTION",
  GET_INPUT_DATA_PRODUCTION_SUCCESS = "GET_INPUT_DATA_PRODUCTION_SUCCESS",
  GET_INPUT_DATA_PRODUCTION_FAIL = "GET_INPUT_DATA_PRODUCTION_FAIL",

  GET_INPUT_DATA_GROSS_REVENUE = "GET_INPUT_DATA_GROSS_REVENUE",
  GET_INPUT_DATA_GROSS_REVENUE_SUCCESS = "GET_INPUT_DATA_GROSS_REVENUE_SUCCESS",
  GET_INPUT_DATA_GROSS_REVENUE_FAIL = "GET_INPUT_DATA_GROSS_REVENUE_FAIL",

  GET_INPUT_DATA_OPEX = "GET_INPUT_DATA_OPEX",
  GET_INPUT_DATA_OPEX_SUCCESS = "GET_INPUT_DATA_OPEX_SUCCESS",
  GET_INPUT_DATA_OPEX_FAIL = "GET_INPUT_DATA_OPEX_FAIL",

  GET_INPUT_DATA_NET_REVENUE = "GET_INPUT_DATA_NET_REVENUE",
  GET_INPUT_DATA_NET_REVENUE_SUCCESS = "GET_INPUT_DATA_NET_REVENUE_SUCCESS",
  GET_INPUT_DATA_NET_REVENUE_FAIL = "GET_INPUT_DATA_NET_REVENUE_FAIL",

  GET_INPUT_DATA_CAPEX = "GET_INPUT_DATA_CAPEX",
  GET_INPUT_DATA_CAPEX_SUCCESS = "GET_INPUT_DATA_CAPEX_SUCCESS",
  GET_INPUT_DATA_CAPEX_FAIL = "GET_INPUT_DATA_CAPEX_FAIL",

  GET_INPUT_DATA_FREE_CASH_FLOW = "GET_INPUT_DATA_FREE_CASH_FLOW",
  GET_INPUT_DATA_FREE_CASH_FLOW_SUCCESS = "GET_INPUT_DATA_FREE_CASH_FLOW_SUCCESS",
  GET_INPUT_DATA_FREE_CASH_FLOW_FAIL = "GET_INPUT_DATA_FREE_CASH_FLOW_FAIL",

  GET_INPUT_DATA_ROACE = "GET_INPUT_DATA_ROACE",
  GET_INPUT_DATA_ROACE_SUCCESS = "GET_INPUT_DATA_ROACE_SUCCESS",
  GET_INPUT_DATA_ROACE_FAIL = "GET_INPUT_DATA_ROACE_FAIL",

  GET_RATING = "GET_RATING",
  GET_RATING_SUCCESS = "GET_RATING_SUCCESS",
  GET_RATING_FAIL = "GET_RATING_FAIL"
}

export type InputDataAndRatingsDashboardsStateType = {
  loading: LoadingType;
  inputDataProduction: InputDataDashboardDataType[];
  inputDataGrossRevenue: InputDataDashboardDataType[];
  inputDataOpex: InputDataDashboardDataType[];
  inputDataNetRevenue: InputDataDashboardDataType[];
  inputDataCapex: InputDataDashboardDataType[];
  inputDataFreeCashFlow: InputDataDashboardDataType[];
  inputDataROACE: InputDataDashboardDataType[];
  rating: InputDataDashboardDataType[];
};

export type LoadingType = {
  inputDataProduction: boolean;
  inputDataGrossRevenue: boolean;
  inputDataOpex: boolean;
  inputDataNetRevenue: boolean;
  inputDataCapex: boolean;
  inputDataFreeCashFlow: boolean;
  inputDataROACE: boolean;
  rating: boolean;
};

export type InputDataAndRatingsDashboardsActionsType =
  //GET_INPUT_DATA_PRODUCTION
  | QueryRequest<InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_PRODUCTION>
  | SuccessResponse<
      InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_PRODUCTION_SUCCESS,
      InputDataDashboardDataType[]
    >
  | FailureResponse<InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_PRODUCTION_FAIL>
  //GET_INPUT_DATA_GROSS_REVENUE
  | QueryRequest<InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_GROSS_REVENUE>
  | SuccessResponse<
      InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_GROSS_REVENUE_SUCCESS,
      InputDataDashboardDataType[]
    >
  | FailureResponse<InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_GROSS_REVENUE_FAIL>
  //GET_INPUT_DATA_OPEX
  | QueryRequest<InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_OPEX>
  | SuccessResponse<
      InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_OPEX_SUCCESS,
      InputDataDashboardDataType[]
    >
  | FailureResponse<InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_OPEX_FAIL>
  //GET_INPUT_DATA_NET_REVENUE
  | QueryRequest<InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_NET_REVENUE>
  | SuccessResponse<
      InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_NET_REVENUE_SUCCESS,
      InputDataDashboardDataType[]
    >
  | FailureResponse<InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_NET_REVENUE_FAIL>
  //GET_INPUT_DATA_CAPEX
  | QueryRequest<InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_CAPEX>
  | SuccessResponse<
      InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_CAPEX_SUCCESS,
      InputDataDashboardDataType[]
    >
  | FailureResponse<InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_CAPEX_FAIL>
  //GET_INPUT_DATA_FREE_CASH_FLOW
  | QueryRequest<InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_FREE_CASH_FLOW>
  | SuccessResponse<
      InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_FREE_CASH_FLOW_SUCCESS,
      InputDataDashboardDataType[]
    >
  | FailureResponse<InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_FREE_CASH_FLOW_FAIL>
  //GET_INPUT_DATA_ROACE
  | QueryRequest<InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_ROACE>
  | SuccessResponse<
      InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_ROACE_SUCCESS,
      InputDataDashboardDataType[]
    >
  | FailureResponse<InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_ROACE_FAIL>
  //GET_RATING
  | QueryRequest<InputDataAndRatingsDashboardsActionTypes.GET_RATING>
  | SuccessResponse<
      InputDataAndRatingsDashboardsActionTypes.GET_RATING_SUCCESS,
      InputDataDashboardDataType[]
    >
  | FailureResponse<InputDataAndRatingsDashboardsActionTypes.GET_RATING_FAIL>;

export enum EInputDataCategories {
  PRODUCTION = "Production",
  GROSS_REVENUE = "GrossRevenue",
  OPEX = "Opex",
  NET_REVENUE = "NetRevenue",
  CAPEX = "Capex",
  FREE_CASH_FLOW = "FreeCashFlow",
  ROACE = "ROACE"
}

export type InputDataDashboardDataType = {
  company: string;
  prev: number;
  cur: number;
  diff_v: number;
  place: number;
  points: number;
};
