import axios, { AxiosPromise, AxiosRequestConfig } from "axios";
import store from "../../store";

export const client = axios.create({
  baseURL: "https://kazenergy-back.dev.timal.kz/api/",
  responseType: "json"
});

// const getToken = () => {
//   const {token} = store.getState().auth;
//   return {Authorization: `${token}`};
// };

// export const startAuth = ({phone}: {phone: string}): Promise<AxiosPromise> => {
//   return client.post('/user/login', {type: 'phone', phone});
// };
//
// export const logout = (): Promise<AxiosPromise> => {
//   return client.post('/user/logout', {}, {headers: getToken()});
// };
//
// const user = {startAuth};

export const config: AxiosRequestConfig<{ query: string }> = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
    // Authorization: 'Token ' + DADATA_TOKEN,
  }
};

// export {user};
