import { applyMiddleware, createStore } from "redux";
import axiosMiddleware from "redux-axios-middleware";
import thunk from "redux-thunk";
import logger from "redux-logger";
import rootReducer from "./reducers";
import { client as axiosClient } from "../services/api";
// import {
//   clearAuthUserData,
//   clearCountNotificationItems,
//   clearDoneItems,
//   clearFavoriteItems,
//   clearNotificationItems,
//   clearPlannedNotification,
//   clearProcessItems,
//   itemsLogout,
// } from 'src/store/actions';

export * from "./actions";

const middlewareConfig: any = {
  interceptors: {
    request: [
      function ({ getState }: { getState: () => RootState }, req: any) {
        // const { token, token_type } = getState().auth;
        req.headers["content-type"] = "application/json";
        // if (token && token_type) {
        //   req.headers.Authorization = `${token_type} ${token}`;
        // }
        return req;
      }
    ],
    response: [
      {
        error: function ({ dispatch }: any, error: any) {
          // if (error.response.status === 401) {
          // dispatch(clearAuthUserData());
          // }
          return Promise.reject(error);
        }
      }
    ]
  }
};

const store = createStore(
  rootReducer,
  applyMiddleware(thunk, axiosMiddleware(axiosClient, middlewareConfig), logger)
);

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
