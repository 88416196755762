import {
  InputDataAndRatingsDashboardsActionsType,
  InputDataAndRatingsDashboardsActionTypes,
  InputDataAndRatingsDashboardsStateType
} from "../../types/store/InputDataAndRatingsDashboardsType";

const initialState: InputDataAndRatingsDashboardsStateType = {
  loading: {
    inputDataProduction: false,
    inputDataGrossRevenue: false,
    inputDataOpex: false,
    inputDataNetRevenue: false,
    inputDataCapex: false,
    inputDataFreeCashFlow: false,
    inputDataROACE: false,
    rating: false
  },
  inputDataProduction: [],
  inputDataGrossRevenue: [],
  inputDataOpex: [],
  inputDataNetRevenue: [],
  inputDataCapex: [],
  inputDataFreeCashFlow: [],
  inputDataROACE: [],
  rating: []
};

export const inputDataAndRatingsDashboardsReducer = (
  state = initialState,
  action: InputDataAndRatingsDashboardsActionsType
): InputDataAndRatingsDashboardsStateType => {
  switch (action.type) {
    //GET_INPUT_DATA_PRODUCTION
    case InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_PRODUCTION:
      return {
        ...state,
        loading: { ...state.loading, inputDataProduction: true }
      };
    case InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_PRODUCTION_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, inputDataProduction: false },
        inputDataProduction: action.payload.data ?? []
      };
    case InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_PRODUCTION_FAIL:
      return {
        ...state,
        loading: { ...state.loading, inputDataProduction: false },
        inputDataProduction: []
      };
    //GET_INPUT_DATA_GROSS_REVENUE
    case InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_GROSS_REVENUE:
      return {
        ...state,
        loading: { ...state.loading, inputDataGrossRevenue: true }
      };
    case InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_GROSS_REVENUE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, inputDataGrossRevenue: false },
        inputDataGrossRevenue: action.payload.data ?? []
      };
    case InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_GROSS_REVENUE_FAIL:
      return {
        ...state,
        loading: { ...state.loading, inputDataGrossRevenue: false },
        inputDataGrossRevenue: []
      };
    //GET_INPUT_DATA_OPEX
    case InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_OPEX:
      return {
        ...state,
        loading: { ...state.loading, inputDataOpex: true }
      };
    case InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_OPEX_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, inputDataOpex: false },
        inputDataOpex: action.payload.data ?? []
      };
    case InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_OPEX_FAIL:
      return {
        ...state,
        loading: { ...state.loading, inputDataOpex: false },
        inputDataOpex: []
      };
    //GET_INPUT_DATA_NET_REVENUE
    case InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_NET_REVENUE:
      return {
        ...state,
        loading: { ...state.loading, inputDataNetRevenue: true }
      };
    case InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_NET_REVENUE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, inputDataNetRevenue: false },
        inputDataNetRevenue: action.payload.data ?? []
      };
    case InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_NET_REVENUE_FAIL:
      return {
        ...state,
        loading: { ...state.loading, inputDataNetRevenue: false },
        inputDataNetRevenue: []
      };
    //GET_INPUT_DATA_CAPEX
    case InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_CAPEX:
      return {
        ...state,
        loading: { ...state.loading, inputDataCapex: true }
      };
    case InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_CAPEX_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, inputDataCapex: false },
        inputDataCapex: action.payload.data ?? []
      };
    case InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_CAPEX_FAIL:
      return {
        ...state,
        loading: { ...state.loading, inputDataCapex: false },
        inputDataCapex: []
      };
    //GET_INPUT_DATA_FREE_CASH_FLOW
    case InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_FREE_CASH_FLOW:
      return {
        ...state,
        loading: { ...state.loading, inputDataFreeCashFlow: true }
      };
    case InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_FREE_CASH_FLOW_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, inputDataFreeCashFlow: false },
        inputDataFreeCashFlow: action.payload.data ?? []
      };
    case InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_FREE_CASH_FLOW_FAIL:
      return {
        ...state,
        loading: { ...state.loading, inputDataFreeCashFlow: false },
        inputDataFreeCashFlow: []
      };
    //GET_INPUT_DATA_ROACE
    case InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_ROACE:
      return {
        ...state,
        loading: { ...state.loading, inputDataROACE: true }
      };
    case InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_ROACE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, inputDataROACE: false },
        inputDataROACE: action.payload.data ?? []
      };
    case InputDataAndRatingsDashboardsActionTypes.GET_INPUT_DATA_ROACE_FAIL:
      return {
        ...state,
        loading: { ...state.loading, inputDataROACE: false },
        inputDataROACE: []
      };
    //GET_RATING
    case InputDataAndRatingsDashboardsActionTypes.GET_RATING:
      return {
        ...state,
        loading: { ...state.loading, rating: true }
      };
    case InputDataAndRatingsDashboardsActionTypes.GET_RATING_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, rating: false },
        rating: action.payload.data ?? []
      };
    case InputDataAndRatingsDashboardsActionTypes.GET_RATING_FAIL:
      return {
        ...state,
        loading: { ...state.loading, rating: false },
        rating: []
      };
    default:
      return state;
  }
};
