import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      Search: "Search…",
      "Welcome back": "Welcome back",
      "We've missed you": "We've missed you"
    }
  },
  ru: {
    translation: {
      Search: "Поиск...",
      "Welcome back": "С возвращением",
      "We've missed you": "Мы соскучились"
    }
  },
  kz: {
    translation: {
      Search: "Іздеу",
      "Welcome back": "Оралуыңмен",
      "We've missed you": "Біз сені сағындық"
    }
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false
  }
});
