import {
  DashboardsActionTypes,
  DashboardsActionsType,
  DashboardsStateType
} from "../../types/store/DashboardsType";

const initialState: DashboardsStateType = {
  loading: {
    D11: false,
    D12: false,
    D21: false,
    D22: false,
    D22a: false,
    D23: false,
    D24: false,
    D24a: false,
    D25: false,
    D26_1: false,
    D26_2: false,
    D26_3: false,
    D31: false,
    D32_1: false,
    D32_2: false,
    D32_3: false,
    D32_4: false,
    D33: false,
    D34: false,
    D35: false,
    D36: false,
    D41: false
  },
  D11: [],
  D12: [],
  D21: [],
  D22: [],
  D22a: [],
  D24: [],
  D25: [],
  D26_1: [],
  D26_2: [],
  D26_3: [],
  D32_1: [],
  D32_2: [],
  D32_3: [],
  D32_4: [],
  D33: [],
  D34: [],
  D35: [],
  D36: [],
  D41: []
};

export const dashboardsReducer = (
  state = initialState,
  action: DashboardsActionsType
): DashboardsStateType => {
  switch (action.type) {
    case DashboardsActionTypes.GET_D11:
      return {
        ...state,
        loading: { ...state.loading, D11: true }
      };
    case DashboardsActionTypes.GET_D11_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, D11: false },
        D11: action.payload.data ?? []
      };
    case DashboardsActionTypes.GET_D11_FAIL:
      return {
        ...state,
        loading: { ...state.loading, D11: false }
      };
    //GET_D11
    case DashboardsActionTypes.GET_D12:
      return {
        ...state,
        loading: { ...state.loading, D12: true }
      };
    case DashboardsActionTypes.GET_D12_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, D12: false },
        D12: action.payload.data ?? []
      };
    case DashboardsActionTypes.GET_D12_FAIL:
      return {
        ...state,
        loading: { ...state.loading, D12: false }
      };
    //GET_D21
    case DashboardsActionTypes.GET_D21:
      return {
        ...state,
        loading: { ...state.loading, D21: true }
      };
    case DashboardsActionTypes.GET_D21_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, D21: false },
        D21: action.payload.data ?? []
      };
    case DashboardsActionTypes.GET_D21_FAIL:
      return {
        ...state,
        loading: { ...state.loading, D21: false }
      };
    //GET_D22
    case DashboardsActionTypes.GET_D22:
      return {
        ...state,
        loading: { ...state.loading, D22: true }
      };
    case DashboardsActionTypes.GET_D22_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, D22: false },
        D22: action.payload.data ?? []
      };
    case DashboardsActionTypes.GET_D22_FAIL:
      return {
        ...state,
        loading: { ...state.loading, D22: false }
      };
    //GET_D22a
    case DashboardsActionTypes.GET_D22a:
      return {
        ...state,
        loading: { ...state.loading, D22a: true }
      };
    case DashboardsActionTypes.GET_D22a_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, D22a: false },
        D22a: action.payload.data ?? []
      };
    case DashboardsActionTypes.GET_D22a_FAIL:
      return {
        ...state,
        loading: { ...state.loading, D22a: false }
      };
    //GET_D24
    case DashboardsActionTypes.GET_D24:
      return {
        ...state,
        loading: { ...state.loading, D24: true }
      };
    case DashboardsActionTypes.GET_D24_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, D24: false },
        D24: action.payload.data ?? []
      };
    case DashboardsActionTypes.GET_D24_FAIL:
      return {
        ...state,
        loading: { ...state.loading, D24: false }
      };
    //GET_D25
    case DashboardsActionTypes.GET_D25:
      return {
        ...state,
        loading: { ...state.loading, D25: true }
      };
    case DashboardsActionTypes.GET_D25_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, D25: false },
        D25: action.payload.data ?? []
      };
    case DashboardsActionTypes.GET_D25_FAIL:
      return {
        ...state,
        loading: { ...state.loading, D25: false }
      };
    //GET_D26_1
    case DashboardsActionTypes.GET_D26_1:
      return {
        ...state,
        loading: { ...state.loading, D26_1: true }
      };
    case DashboardsActionTypes.GET_D26_1_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, D26_1: false },
        D26_1: action.payload.data ?? []
      };
    case DashboardsActionTypes.GET_D26_1_FAIL:
      return {
        ...state,
        loading: { ...state.loading, D26_1: false }
      };
    //GET_D26_2
    case DashboardsActionTypes.GET_D26_2:
      return {
        ...state,
        loading: { ...state.loading, D26_2: true }
      };
    case DashboardsActionTypes.GET_D26_2_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, D26_2: false },
        D26_2: action.payload.data ?? []
      };
    case DashboardsActionTypes.GET_D26_2_FAIL:
      return {
        ...state,
        loading: { ...state.loading, D26_2: false }
      };
    //GET_D26_3
    case DashboardsActionTypes.GET_D26_3:
      return {
        ...state,
        loading: { ...state.loading, D26_3: true }
      };
    case DashboardsActionTypes.GET_D26_3_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, D26_3: false },
        D26_3: action.payload.data ?? []
      };
    case DashboardsActionTypes.GET_D26_3_FAIL:
      return {
        ...state,
        loading: { ...state.loading, D26_3: false }
      };
    //GET_D32_1
    case DashboardsActionTypes.GET_D32_1:
      return {
        ...state,
        loading: { ...state.loading, D32_1: true }
      };
    case DashboardsActionTypes.GET_D32_1_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, D32_1: false },
        D32_1: action.payload.data ?? []
      };
    case DashboardsActionTypes.GET_D32_1_FAIL:
      return {
        ...state,
        loading: { ...state.loading, D32_1: false }
      };
    //GET_D32_2
    case DashboardsActionTypes.GET_D32_2:
      return {
        ...state,
        loading: { ...state.loading, D32_2: true }
      };
    case DashboardsActionTypes.GET_D32_2_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, D32_2: false },
        D32_2: action.payload.data ?? []
      };
    case DashboardsActionTypes.GET_D32_2_FAIL:
      return {
        ...state,
        loading: { ...state.loading, D32_2: false }
      };
    //GET_D32_3
    case DashboardsActionTypes.GET_D32_3:
      return {
        ...state,
        loading: { ...state.loading, D32_3: true }
      };
    case DashboardsActionTypes.GET_D32_3_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, D32_3: false },
        D32_3: action.payload.data ?? []
      };
    case DashboardsActionTypes.GET_D32_3_FAIL:
      return {
        ...state,
        loading: { ...state.loading, D32_3: false }
      };
    //GET_D32_4
    case DashboardsActionTypes.GET_D32_4:
      return {
        ...state,
        loading: { ...state.loading, D32_4: true }
      };
    case DashboardsActionTypes.GET_D32_4_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, D32_4: false },
        D32_4: action.payload.data ?? []
      };
    case DashboardsActionTypes.GET_D32_4_FAIL:
      return {
        ...state,
        loading: { ...state.loading, D32_4: false }
      };
    //GET_D33
    case DashboardsActionTypes.GET_D33:
      return {
        ...state,
        loading: { ...state.loading, D33: true }
      };
    case DashboardsActionTypes.GET_D33_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, D33: false },
        D33: action.payload.data ?? []
      };
    case DashboardsActionTypes.GET_D33_FAIL:
      return {
        ...state,
        loading: { ...state.loading, D33: false }
      };
    //GET_D34
    case DashboardsActionTypes.GET_D34:
      return {
        ...state,
        loading: { ...state.loading, D34: true }
      };
    case DashboardsActionTypes.GET_D34_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, D34: false },
        D34: action.payload.data ?? []
      };
    case DashboardsActionTypes.GET_D34_FAIL:
      return {
        ...state,
        loading: { ...state.loading, D34: false }
      };
    //GET_D35
    case DashboardsActionTypes.GET_D35:
      return {
        ...state,
        loading: { ...state.loading, D35: true }
      };
    case DashboardsActionTypes.GET_D35_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, D35: false },
        D35: action.payload.data ?? []
      };
    case DashboardsActionTypes.GET_D35_FAIL:
      return {
        ...state,
        loading: { ...state.loading, D35: false }
      };
    //GET_D36
    case DashboardsActionTypes.GET_D36:
      return {
        ...state,
        loading: { ...state.loading, D36: true }
      };
    case DashboardsActionTypes.GET_D36_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, D36: false },
        D36: action.payload.data ?? []
      };
    case DashboardsActionTypes.GET_D36_FAIL:
      return {
        ...state,
        loading: { ...state.loading, D36: false }
      };
    //GET_D41
    case DashboardsActionTypes.GET_D41:
      return {
        ...state,
        loading: { ...state.loading, D41: true }
      };
    case DashboardsActionTypes.GET_D41_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, D41: false },
        D41: action.payload.data ?? []
      };
    case DashboardsActionTypes.GET_D41_FAIL:
      return {
        ...state,
        loading: { ...state.loading, D41: false }
      };

    default:
      return state;
  }
};
