import React, { useCallback } from "react";
import { MoreVertical } from "react-feather";
import { Tooltip, Menu, MenuItem, IconButton } from "@mui/material";
import { jsPDF } from "jspdf";
import { utils, writeFileXLSX } from "xlsx";

const convertToCSV = (jsonData: any) => {
  const headers = Object.keys(jsonData[0]);
  const values = jsonData.map((obj) => headers.map((header) => obj[header]));

  const headerStr = headers.join(",") + "\n";
  const valueStr = values.map((row) => row.join(",")).join("\n");

  return headerStr + valueStr;
};

type Props = {
  chartId?: string;
  jsonData?: any;
};

const ExportDropdown: React.FC<Props> = ({ chartId, jsonData }) => {
  const [anchorMenu, setAnchorMenu] = React.useState(null);

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const exportPdf = useCallback(() => {
    if (chartId) {
      ApexCharts.exec(chartId, "dataURI", { scale: 2 }).then(({ imgURI }) => {
        const pdf = new jsPDF({ compress: false, orientation: "landscape" });
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        const img = new Image();
        img.src = imgURI;

        img.onload = () => {
          const width = img.width;
          const height = img.height;
          const widthRatio = pdfWidth / width;
          const heightRatio = pdfHeight / height;

          const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

          pdf.addImage(imgURI, "JPEG", 0, 10, width * ratio, height * ratio, "", "NONE");
          pdf.save("chart.pdf");
        };
      });
    }

    closeMenu();
  }, [chartId]);

  const exportExcel = useCallback(() => {
    if (chartId && jsonData) {
      let ws = utils.json_to_sheet(jsonData);
      let wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "sheet");
      writeFileXLSX(wb, `chart.xlsx`);
    }

    closeMenu();
  }, [chartId, jsonData]);

  const exportJPEG = useCallback(() => {
    if (chartId) {
      ApexCharts.exec(chartId, "dataURI", { scale: 2 }).then(({ imgURI }) => {
        const byteString = atob(imgURI.split(",")[1]);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
          uint8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([arrayBuffer], { type: "image/jpeg" });

        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = "chart";
        a.click();

        URL.revokeObjectURL(url);
      });
    }

    closeMenu();
  }, [chartId]);

  const exportCsv = useCallback(() => {
    if (chartId && jsonData) {
      const blob = new Blob([convertToCSV(jsonData)], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.href = url;
      a.download = "chart";
      a.click();

      URL.revokeObjectURL(url);
    }

    closeMenu();
  }, [chartId, jsonData]);

  const exportJson = useCallback(() => {
    if (chartId && jsonData) {
      const blob = new Blob([JSON.stringify(jsonData, null, 2)], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.href = url;
      a.download = "chart";
      a.click();

      URL.revokeObjectURL(url);
    }

    closeMenu();
  }, [chartId, jsonData]);

  return (
    <React.Fragment>
      <Tooltip title='Export'>
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup='true'
          onClick={toggleMenu}
          aria-label='settings'
          size='large'
        >
          <MoreVertical />
        </IconButton>
      </Tooltip>
      <Menu
        id='menu-export'
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
        PaperProps={{ style: { boxShadow: "0px 0px 10px -5px rgba(0,0,0,0.7)" } }}
      >
        <MenuItem onClick={exportPdf}>Export as PDF</MenuItem>
        <MenuItem onClick={exportExcel}>Export as Excel</MenuItem>
        <MenuItem onClick={exportJPEG}>Export as JPEG</MenuItem>
        <MenuItem onClick={exportCsv}>Export as CSV</MenuItem>
        <MenuItem onClick={exportJson}>Export as JSON</MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default ExportDropdown;
