import { combineReducers } from "redux";
// import {reducer as form} from 'redux-form';
import { dashboardsReducer } from "./dashboards";
import { inputDataAndRatingsDashboardsReducer } from "./inputDataAndRatingsDashboards";

const rootReducer = combineReducers({
  dashboards: dashboardsReducer,
  inputDataAndRatingsDashboards: inputDataAndRatingsDashboardsReducer
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
