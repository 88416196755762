import { QueryRequest } from "../../types/store/ReduxMiddleWareType";
import { DashboardsActionTypes, ERegions } from "../../types/store/DashboardsType";

export const getD11 = (): QueryRequest<DashboardsActionTypes.GET_D11> => {
  return {
    type: DashboardsActionTypes.GET_D11,
    payload: {
      request: {
        url: "d.1.1",
        method: "GET"
      }
    }
  };
};

export const getD12 = (): QueryRequest<DashboardsActionTypes.GET_D12> => {
  return {
    type: DashboardsActionTypes.GET_D12,
    payload: {
      request: {
        url: "d.1.2",
        method: "GET"
      }
    }
  };
};

export const getD21 = (): QueryRequest<DashboardsActionTypes.GET_D21> => {
  return {
    type: DashboardsActionTypes.GET_D21,
    payload: {
      request: {
        url: "d.2.1",
        method: "GET"
      }
    }
  };
};

export const getD22 = (): QueryRequest<DashboardsActionTypes.GET_D22> => {
  return {
    type: DashboardsActionTypes.GET_D22,
    payload: {
      request: {
        url: "d.2.2",
        method: "GET"
      }
    }
  };
};

export const getD22a = (): QueryRequest<DashboardsActionTypes.GET_D22a> => {
  return {
    type: DashboardsActionTypes.GET_D22a,
    payload: {
      request: {
        url: "d.2.2a",
        method: "GET"
      }
    }
  };
};

export const getD24 = (
  region: ERegions = ERegions.ATYRAU
): QueryRequest<DashboardsActionTypes.GET_D24> => {
  return {
    type: DashboardsActionTypes.GET_D24,
    payload: {
      request: {
        url: `d.2.4?region=${region}`,
        method: "GET"
      }
    }
  };
};

export const getD25 = (): QueryRequest<DashboardsActionTypes.GET_D25> => {
  return {
    type: DashboardsActionTypes.GET_D25,
    payload: {
      request: {
        url: "d.2.5",
        method: "GET"
      }
    }
  };
};

export const getD26_1 = (year: number): QueryRequest<DashboardsActionTypes.GET_D26_1> => {
  return {
    type: DashboardsActionTypes.GET_D26_1,
    payload: {
      request: {
        url: `d.2.6?year=${year}`,
        method: "GET"
      }
    }
  };
};

export const getD26_2 = (year: number): QueryRequest<DashboardsActionTypes.GET_D26_2> => {
  return {
    type: DashboardsActionTypes.GET_D26_2,
    payload: {
      request: {
        url: `d.2.6?year=${year}`,
        method: "GET"
      }
    }
  };
};

export const getD26_3 = (year: number): QueryRequest<DashboardsActionTypes.GET_D26_3> => {
  return {
    type: DashboardsActionTypes.GET_D26_3,
    payload: {
      request: {
        url: `d.2.6?year=${year}`,
        method: "GET"
      }
    }
  };
};

export const getD32_1 = (year: number): QueryRequest<DashboardsActionTypes.GET_D32_1> => {
  return {
    type: DashboardsActionTypes.GET_D32_1,
    payload: {
      request: {
        url: `d.3.2?year=${year}`,
        method: "GET"
      }
    }
  };
};

export const getD32_2 = (year: number): QueryRequest<DashboardsActionTypes.GET_D32_2> => {
  return {
    type: DashboardsActionTypes.GET_D32_2,
    payload: {
      request: {
        url: `d.3.2?year=${year}`,
        method: "GET"
      }
    }
  };
};

export const getD32_3 = (year: number): QueryRequest<DashboardsActionTypes.GET_D32_3> => {
  return {
    type: DashboardsActionTypes.GET_D32_3,
    payload: {
      request: {
        url: `d.3.2?year=${year}`,
        method: "GET"
      }
    }
  };
};

export const getD32_4 = (year: number): QueryRequest<DashboardsActionTypes.GET_D32_4> => {
  return {
    type: DashboardsActionTypes.GET_D32_4,
    payload: {
      request: {
        url: `d.3.2?year=${year}`,
        method: "GET"
      }
    }
  };
};

export const getD33 = (): QueryRequest<DashboardsActionTypes.GET_D33> => {
  return {
    type: DashboardsActionTypes.GET_D33,
    payload: {
      request: {
        url: "d.3.3",
        method: "GET"
      }
    }
  };
};

export const getD34 = (): QueryRequest<DashboardsActionTypes.GET_D34> => {
  return {
    type: DashboardsActionTypes.GET_D34,
    payload: {
      request: {
        url: "d.3.4",
        method: "GET"
      }
    }
  };
};

export const getD35 = (city: string): QueryRequest<DashboardsActionTypes.GET_D35> => {
  return {
    type: DashboardsActionTypes.GET_D35,
    payload: {
      request: {
        url: `d.3.5?city=${city}`,
        method: "GET"
      }
    }
  };
};

export const getD36 = (city: string, year: number): QueryRequest<DashboardsActionTypes.GET_D36> => {
  return {
    type: DashboardsActionTypes.GET_D36,
    payload: {
      request: {
        url: `d.3.6?city=${city}&year=${year}`,
        method: "GET"
      }
    }
  };
};

export const getD41 = (): QueryRequest<DashboardsActionTypes.GET_D41> => {
  return {
    type: DashboardsActionTypes.GET_D41,
    payload: {
      request: {
        url: "d.4.1",
        method: "GET"
      }
    }
  };
};
