import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import Chart from "react-apexcharts";

import { CardContent, Card as MuiCard, CardHeader, Theme } from "@mui/material";
import { spacing } from "@mui/system";
import ExportDropdown from "../../../components/ExportDropdown";
import { ApexOptions } from "apexcharts";
import { D11DataType } from "../../../types/store/DashboardsType";
import Loader from "../../../components/Loader";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 350px;
  width: 100%;
`;

interface IProps {
  theme: Theme;
  data: D11DataType[];
  loading: boolean;
}

const MixedChartD11: React.FC<IProps> = ({ theme, data, loading }) => {
  const labels = [
    {
      seriesName: "Number of rows",
      measure: "",
      opposite: false
    },
    {
      seriesName: "Number of fields",
      measure: "",
      opposite: true
    }
  ];

  const series: ApexOptions["series"] = [
    {
      name: labels[0].seriesName,
      type: "line",
      data: data.map((item) => item.numberOfRows)
    },
    {
      name: labels[1].seriesName,
      type: "column",
      data: data.map((item) => item.numberOfFields)
    }
  ];

  const options: ApexOptions = {
    chart: {
      stacked: false,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: true
      }
    },
    stroke: {
      width: [3, 0],
      curve: "smooth"
    },
    legend: {
      show: false
    },
    plotOptions: {
      bar: {
        columnWidth: "10"
      }
    },
    labels: data.map((item) => item.date),
    markers: {
      size: 5
    },
    yaxis: labels.map((label) => ({
      opposite: label.opposite,
      title: {
        text: label.seriesName
      },
      seriesName: label.seriesName,
      min: 0
    })),
    tooltip: {
      shared: false,
      intersect: false,
      y: {
        formatter: function (y, { seriesIndex }) {
          if (typeof y !== "undefined") {
            return y + ` ${labels[seriesIndex].measure}`;
          }
          return y;
        }
      }
    },
    colors: [theme.palette.error.dark, theme.palette.primary.light]
  };

  return (
    <Card mb={6}>
      <CardHeader action={<ExportDropdown />} title='DataBase entries' />
      <CardContent>
        {loading ? (
          <Loader />
        ) : (
          <ChartWrapper>
            <Chart options={options} series={series} height='350' />
          </ChartWrapper>
        )}
      </CardContent>
    </Card>
  );
};

export default withTheme(MixedChartD11);
