import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Box, Drawer as MuiDrawer, ListItemButton } from "@mui/material";

import logo from "../../vendor/logo.svg";
import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)`
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  padding-top: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const Logo = styled(Box)`
  margin-right: ${(props) => props.theme.spacing(2)};
  width: 100%;
  height: 50px;
  object-fit: cover;
  object-position: center;
  transform: scale(1.15);
`;

const Sidebar = ({ items, showFooter = true, ...rest }) => {
  return (
    <Drawer variant='permanent' {...rest}>
      <Brand component={NavLink} to='/'>
        <Logo component='img' alt='Logo' src={logo} />
      </Brand>
      <SidebarNav items={items} />
      {!!showFooter && <Footer />}
    </Drawer>
  );
};

export default Sidebar;
