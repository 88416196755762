import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useActions } from "../../../hooks/useActions";
import { useTypedSelector } from "../../../hooks/useTypedSelector";

import { Grid, Divider as MuiDivider, Typography as MuiTypography } from "@mui/material";
import { spacing } from "@mui/system";

import Actions from "./Actions";
import TableD12 from "./TableD12";
import MixedChart from "./MixedChartD11";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

function RepositoryStatistics() {
  const { t } = useTranslation();

  const { getD11, getD12 } = useActions();
  const { D11, D12, loading } = useTypedSelector((state) => state.dashboards);

  useEffect(() => {
    getD11();
    getD12();
  }, []);

  return (
    <React.Fragment>
      <Helmet title='Repository Statistics Dashboard' />
      <Grid justifyContent='space-between' container spacing={6}>
        <Grid item>
          <Typography variant='h3' gutterBottom>
            Repository Statistics Dashboard
          </Typography>
        </Grid>

        <Grid item>
          <Actions />
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <MixedChart data={D11} loading={loading.D11} />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <TableD12 data={D12} loading={loading.D12} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default RepositoryStatistics;
