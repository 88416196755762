import { FailureResponse, QueryRequest, SuccessResponse } from "./ReduxMiddleWareType";

export enum DashboardsActionTypes {
  GET_D11 = "GET_D11",
  GET_D11_SUCCESS = "GET_D11_SUCCESS",
  GET_D11_FAIL = "GET_D11_FAIL",

  GET_D12 = "GET_D12",
  GET_D12_SUCCESS = "GET_D12_SUCCESS",
  GET_D12_FAIL = "GET_D12_FAIL",

  GET_D21 = "GET_D21",
  GET_D21_SUCCESS = "GET_D21_SUCCESS",
  GET_D21_FAIL = "GET_D21_FAIL",

  GET_D22 = "GET_D22",
  GET_D22_SUCCESS = "GET_D22_SUCCESS",
  GET_D22_FAIL = "GET_D22_FAIL",

  GET_D22a = "GET_D22a",
  GET_D22a_SUCCESS = "GET_D22a_SUCCESS",
  GET_D22a_FAIL = "GET_D22a_FAIL",

  GET_D24 = "GET_D24",
  GET_D24_SUCCESS = "GET_D24_SUCCESS",
  GET_D24_FAIL = "GET_D24_FAIL",

  GET_D25 = "GET_D25",
  GET_D25_SUCCESS = "GET_D25_SUCCESS",
  GET_D25_FAIL = "GET_D25_FAIL",

  GET_D26_1 = "GET_D26_1",
  GET_D26_1_SUCCESS = "GET_D26_1_SUCCESS",
  GET_D26_1_FAIL = "GET_D26_1_FAIL",

  GET_D26_2 = "GET_D26_2",
  GET_D26_2_SUCCESS = "GET_D26_2_SUCCESS",
  GET_D26_2_FAIL = "GET_D26_2_FAIL",

  GET_D26_3 = "GET_D26_3",
  GET_D26_3_SUCCESS = "GET_D26_3_SUCCESS",
  GET_D26_3_FAIL = "GET_D26_3_FAIL",

  GET_D32_1 = "GET_D32_1",
  GET_D32_1_SUCCESS = "GET_D32_1_SUCCESS",
  GET_D32_1_FAIL = "GET_D32_1_FAIL",

  GET_D32_2 = "GET_D32_2",
  GET_D32_2_SUCCESS = "GET_D32_2_SUCCESS",
  GET_D32_2_FAIL = "GET_D32_2_FAIL",

  GET_D32_3 = "GET_D32_3",
  GET_D32_3_SUCCESS = "GET_D32_3_SUCCESS",
  GET_D32_3_FAIL = "GET_D32_3_FAIL",

  GET_D32_4 = "GET_D32_4",
  GET_D32_4_SUCCESS = "GET_D32_4_SUCCESS",
  GET_D32_4_FAIL = "GET_D32_4_FAIL",

  GET_D33 = "GET_D33",
  GET_D33_SUCCESS = "GET_D33_SUCCESS",
  GET_D33_FAIL = "GET_D33_FAIL",

  GET_D34 = "GET_D34",
  GET_D34_SUCCESS = "GET_D34_SUCCESS",
  GET_D34_FAIL = "GET_D34_FAIL",

  GET_D35 = "GET_D35",
  GET_D35_SUCCESS = "GET_D35_SUCCESS",
  GET_D35_FAIL = "GET_D35_FAIL",

  GET_D36 = "GET_D36",
  GET_D36_SUCCESS = "GET_D36_SUCCESS",
  GET_D36_FAIL = "GET_D36_FAIL",

  GET_D41 = "GET_D41",
  GET_D41_SUCCESS = "GET_D41_SUCCESS",
  GET_D41_FAIL = "GET_D41_FAIL"
}

export type DashboardsStateType = {
  loading: LoadingType;
  D11: D11DataType[];
  D12: D12DataType[];
  D21: D21DataType[];
  D22: D22DataType[];
  D22a: D22aDataType[];
  D24: D24DataType[];
  D25: D25DataType[];
  D26_1: D26DataType[];
  D26_2: D26DataType[];
  D26_3: D26DataType[];
  D32_1: D32DataType[];
  D32_2: D32DataType[];
  D32_3: D32DataType[];
  D32_4: D32DataType[];
  D33: D33DataType[];
  D34: D34DataType[];
  D35: D35DataType[];
  D36: D36DataType[];
  D41: D41DataType[];
};

export type LoadingType = {
  D11: boolean;
  D12: boolean;
  D21: boolean;
  D22: boolean;
  D22a: boolean;
  D23: boolean;
  D24: boolean;
  D24a: boolean;
  D25: boolean;
  D26_1: boolean;
  D26_2: boolean;
  D26_3: boolean;
  D31: boolean;
  D32_1: boolean;
  D32_2: boolean;
  D32_3: boolean;
  D32_4: boolean;
  D33: boolean;
  D34: boolean;
  D35: boolean;
  D36: boolean;
  D41: boolean;
};

export type DashboardsActionsType =
  //GET D11
  | QueryRequest<DashboardsActionTypes.GET_D11>
  | SuccessResponse<DashboardsActionTypes.GET_D11_SUCCESS, D11DataType[]>
  | FailureResponse<DashboardsActionTypes.GET_D11_FAIL>
  //GET D12
  | QueryRequest<DashboardsActionTypes.GET_D12>
  | SuccessResponse<DashboardsActionTypes.GET_D12_SUCCESS, D12DataType[]>
  | FailureResponse<DashboardsActionTypes.GET_D12_FAIL>
  //GET D21
  | QueryRequest<DashboardsActionTypes.GET_D21>
  | SuccessResponse<DashboardsActionTypes.GET_D21_SUCCESS, D21DataType[]>
  | FailureResponse<DashboardsActionTypes.GET_D21_FAIL>
  //GET D22
  | QueryRequest<DashboardsActionTypes.GET_D22>
  | SuccessResponse<DashboardsActionTypes.GET_D22_SUCCESS, D22DataType[]>
  | FailureResponse<DashboardsActionTypes.GET_D22_FAIL>
  //GET D22a
  | QueryRequest<DashboardsActionTypes.GET_D22a>
  | SuccessResponse<DashboardsActionTypes.GET_D22a_SUCCESS, D22aDataType[]>
  | FailureResponse<DashboardsActionTypes.GET_D22a_FAIL>
  //GET D24
  | QueryRequest<DashboardsActionTypes.GET_D24>
  | SuccessResponse<DashboardsActionTypes.GET_D24_SUCCESS, D24DataType[]>
  | FailureResponse<DashboardsActionTypes.GET_D24_FAIL>
  //GET D25
  | QueryRequest<DashboardsActionTypes.GET_D25>
  | SuccessResponse<DashboardsActionTypes.GET_D25_SUCCESS, D25DataType[]>
  | FailureResponse<DashboardsActionTypes.GET_D25_FAIL>
  //GET D26_1
  | QueryRequest<DashboardsActionTypes.GET_D26_1>
  | SuccessResponse<DashboardsActionTypes.GET_D26_1_SUCCESS, D26DataType[]>
  | FailureResponse<DashboardsActionTypes.GET_D26_1_FAIL>
  //GET D26_2
  | QueryRequest<DashboardsActionTypes.GET_D26_2>
  | SuccessResponse<DashboardsActionTypes.GET_D26_2_SUCCESS, D26DataType[]>
  | FailureResponse<DashboardsActionTypes.GET_D26_2_FAIL>
  //GET D26_3
  | QueryRequest<DashboardsActionTypes.GET_D26_3>
  | SuccessResponse<DashboardsActionTypes.GET_D26_3_SUCCESS, D26DataType[]>
  | FailureResponse<DashboardsActionTypes.GET_D26_3_FAIL>
  //GET D32_1
  | QueryRequest<DashboardsActionTypes.GET_D32_1>
  | SuccessResponse<DashboardsActionTypes.GET_D32_1_SUCCESS, D32DataType[]>
  | FailureResponse<DashboardsActionTypes.GET_D32_1_FAIL>
  //GET D32_2
  | QueryRequest<DashboardsActionTypes.GET_D32_2>
  | SuccessResponse<DashboardsActionTypes.GET_D32_2_SUCCESS, D32DataType[]>
  | FailureResponse<DashboardsActionTypes.GET_D32_2_FAIL>
  //GET D32_3
  | QueryRequest<DashboardsActionTypes.GET_D32_3>
  | SuccessResponse<DashboardsActionTypes.GET_D32_3_SUCCESS, D32DataType[]>
  | FailureResponse<DashboardsActionTypes.GET_D32_3_FAIL>
  //GET D32_4
  | QueryRequest<DashboardsActionTypes.GET_D32_4>
  | SuccessResponse<DashboardsActionTypes.GET_D32_4_SUCCESS, D32DataType[]>
  | FailureResponse<DashboardsActionTypes.GET_D32_4_FAIL>
  //GET D33
  | QueryRequest<DashboardsActionTypes.GET_D33>
  | SuccessResponse<DashboardsActionTypes.GET_D33_SUCCESS, D33DataType[]>
  | FailureResponse<DashboardsActionTypes.GET_D33_FAIL>
  //GET D34
  | QueryRequest<DashboardsActionTypes.GET_D34>
  | SuccessResponse<DashboardsActionTypes.GET_D34_SUCCESS, D34DataType[]>
  | FailureResponse<DashboardsActionTypes.GET_D34_FAIL>
  //GET D35
  | QueryRequest<DashboardsActionTypes.GET_D35>
  | SuccessResponse<DashboardsActionTypes.GET_D35_SUCCESS, D35DataType[]>
  | FailureResponse<DashboardsActionTypes.GET_D35_FAIL>
  //GET D36
  | QueryRequest<DashboardsActionTypes.GET_D36>
  | SuccessResponse<DashboardsActionTypes.GET_D36_SUCCESS, D36DataType[]>
  | FailureResponse<DashboardsActionTypes.GET_D36_FAIL>
  //GET D41
  | QueryRequest<DashboardsActionTypes.GET_D41>
  | SuccessResponse<DashboardsActionTypes.GET_D41_SUCCESS, D41DataType[]>
  | FailureResponse<DashboardsActionTypes.GET_D41_FAIL>;

export type D11DataType = {
  date: string;
  numberOfRows: number;
  numberOfFields: number;
};

export enum EUpdateStatus {
  UPDATED = "Updated",
  NOT_UPDATED = "Not updated"
}

export type D12DataType = {
  name: string;
  startDate: string;
  endDate: string;
  countOfRows: number;
  state: EUpdateStatus;
};

export type D21DataType = {
  region: string;
  status: string;
};

export type D22DataType = {
  date: string;
  region: string;
  production: number;
};

export type D22aDataType = {
  region: string;
  totalProduction: number;
  totalShare: number;
};

export enum ERegions {
  ATYRAU = "Atyrau",
  MANGISTAU = "Mangistau",
  AKTOBE = "Aktobe",
  KYZYLORDA = "Kyzylorda",
  ZKO = "ZKO"
}

export type D24DataType = {
  date: string;
  companyName: string;
  companyProduction: number;
};

export type D25DataType = {
  date: string;
  region: string;
  production: number;
};

export type D26DataType = {
  region: string;
  production: number;
  trend: number;
  relTrend: number;
};

export type D32DataType = {
  refineryName: string;
  refining: number;
  rate: number;
  trend: number;
  relTrend: number;
};

export type D33DataType = {
  date: string;
  refineryName: string;
  capacity: number;
  production: number;
};

export type D34DataType = {
  region: string;
  refining: number;
};

export type D35DataType = {
  date: string;
  petrol: number | null;
  kerosin: number | null;
  diesel: number | null;
  mazut: number | null;
};

export type D36DataType = {
  name: string;
  prod: string | number | null;
};

export type D41DataType = {
  date: string;
  WPriceBrent: number | null;
  WPriceUrals: number | null;
  WPriceKEPCO: number | null;
  InflationUSD: number | null;
  InflationKZT: number | null;
  RateUSDKZT: number | null;
  ROIRate: number | null;
  RISKRTNAV: number | null;
  WPriceBrent_diff: number | null;
  WPriceUrals_diff: number | null;
  WPriceKEPCO_diff: number | null;
  InflationUSD_diff: number | null;
  InflationKZT_diff: number | null;
  RateUSDKZT_diff: number | null;
  ROIRate_diff: number | null;
  RISKRTNAV_diff: number | null;
};
